import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Before my first dev job`}</h1>
    <p>{`Before getting my firast job I worked at the school cafeteria, my sister some how got the principal to let her and me to run
the place, we needed to buy all the food and speed the break selling it, we did this for the last 2 years of high school. I loved
that I had this opportunity it allow me to learn the value of money at an early age. And set me up for my next invented job as a teenager.`}</p>
    <p>{`One day I needed a phone because I broke the one I had, I remember that My dad had a water-damaged phone that he was going to throw away,
so I asked him if I could have it, he said yes, I took it apart and clean it with alcohol, miraculously it worked, I was so happy that I
could bring back phones to life, quickly I started thinking on what else I could fix and sell, I didn't have money I only had the money from
the cafeteria, so I started buying broken phones from facebook groups, fix then and resell for profit.`}</p>
    <p>{`I did earn that much, but that wasn't the point, I just wanted to get better phones an play with technology. I never stop
fixing broken PCBs but now a days I manly fix my own stuff, our from friends and family.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      